import { Controller } from '@hotwired/stimulus'

export default class DropdownController extends Controller {
  toggleSubmenu (event) {
    const submenuId = event.currentTarget.id.replace('btndropdown', 'submenu')
    const submenu = document.getElementById(submenuId)
    const icon = event.currentTarget.querySelector('svg')

    if (submenu) {
      submenu.classList.toggle('hidden')
      icon.classList.toggle('rotate-0')
      icon.classList.toggle('-rotate-90')
    }
  }
}
